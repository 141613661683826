export const PART_GENRE_LIST = {
  0: 'その他',
  1: 'サドル',
  2: 'シートポスト',
  3: 'スプロケット/ギア',
  4: 'タイヤ/チューブ',
  5: 'チェーン',
  6: 'チェーンリング/クランク',
  7: 'ディレイラー',
  8: 'プーリー',
  9: 'ハンドル/ステム',
  10: 'バーテープ/グリップ',
  11: 'ブレーキ',
  12: 'ブレーキレバー/シフトレバー',
  13: 'ブレーキケーブル/シフトケーブル',
  14: 'フレーム/フォーク',
  15: 'ペダル',
  16: 'ヘッドパーツ/コラムスペーサー',
  17: 'ホイール/ハブ/リム',
  18: 'ＢＢ(ボトムブラケット)',
  19: 'ワイヤー',
  20: 'コンポーネント',
  21: '電動アシスト',
}

export type PartGenreKey = keyof typeof PART_GENRE_LIST

export const reversedPartGenre = Object.fromEntries(
  Object.entries(PART_GENRE_LIST).map(function(value) {
    return [value[1], Number(value[0])]
  })
)

export type ReversedPartGenreKey = keyof typeof reversedPartGenre

export class PartGenreModel {
  public readonly value: number
  constructor(value: number) {
    this.value = value
  }
  public toJSON(): number {
    return this.value
  }
  public toNumber(): number {
    return this.value
  }
  public toString(): string {
    return this.value.toString()
  }
  public getDisplayName(): string {
    return PART_GENRE_LIST[this.value as PartGenreKey]
  }
  public static getDisplayName(key: number): string {
    if (!(key in PART_GENRE_LIST))
      throw new Error(`got invalid key ${key}`)
    return PART_GENRE_LIST[key as PartGenreKey]
  }
  public static getNumber(displayName: string): number {
    return Number(reversedPartGenre[displayName])
  }
  public static list(): Array<[string, string]> {
    return Object.entries(PART_GENRE_LIST)
  }
}

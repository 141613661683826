export const SUPPLY_GENRE_LIST = {
  0: 'その他',
  1: 'サングラス/ゴーグル',
  2: 'ヘルメット',
  3: 'キャップ/バンダナ',
  4: 'フェイスマスク/ネックウェア',
  5: 'グローブ',
  6: 'アームカバー',
  7: 'ジャージ',
  8: 'ジャケット/ウィンドブレーカー',
  9: 'インナーウェア',
  10: 'パンツ/スカート',
  11: 'レーサーパンツ/タイツ',
  12: 'レッグカバー',
  13: 'シューズ/シューズカバー',
  14: 'ソックス',
  15: 'プロテクター',
  16: 'レインウェア',
  17: '裾バンド',
  18: 'バッグ',
  19: '財布・サイクルウォレット',
  20: 'ウェア他',
  21: 'ライト',
  22: 'ロック',
  23: '空気入れ',
  24: 'サイクルコンピューター',
  25: 'スタンド',
  26: 'ボトル、ボトルケージ',
  27: 'サイクルトレーナー',
  28: 'アクセサリー他',
  29: '工具',
  30: 'ケミカル',
}

export type SupplyGenreKey = keyof typeof SUPPLY_GENRE_LIST

export const reversedSupplyGenre = Object.fromEntries(
  Object.entries(SUPPLY_GENRE_LIST).map(function(value) {
    return [value[1], Number(value[0])]
  })
)

export type ReversedSupplyGenreKey = keyof typeof reversedSupplyGenre

export class SupplyGenreModel {
  public readonly value: number
  constructor(value: number) {
    this.value = value
  }
  public toJSON(): number {
    return this.value
  }
  public toNumber(): number {
    return this.value
  }
  public toString(): string {
    return this.value.toString()
  }
  public getDisplayName(): string {
    return SUPPLY_GENRE_LIST[this.value as SupplyGenreKey]
  }
  public static getDisplayName(key: number): string {
    if (!(key in SUPPLY_GENRE_LIST))
      throw new Error(`got invalid key ${key}`)
    return SUPPLY_GENRE_LIST[key as SupplyGenreKey]
  }
  public static getNumber(displayName: string): number {
    return Number(reversedSupplyGenre[displayName])
  }
  public static list(): Array<[string, string]> {
    return Object.entries(SUPPLY_GENRE_LIST)
  }
}

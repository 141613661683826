export const BICYCLE_GENRE_LIST = {
	0: 'その他',
	1: 'ロード',
	2: 'MTB',
	3: 'クロスバイク',
	4: 'ＴＴ',
	5: 'グラベル',
	6: 'シクロクロス',
	7: 'E-Bike',
	8: 'ミニベロ',
	9: 'ピスト',
	10: 'ランドナー',
} as const

type BICYCLE_GENRE_LIST_VALUES = typeof BICYCLE_GENRE_LIST[keyof typeof BICYCLE_GENRE_LIST]

export type GenreKey = keyof typeof BICYCLE_GENRE_LIST

export const reversedGenre = Object.fromEntries(
	Object.entries(BICYCLE_GENRE_LIST).map(function(value) {
		return [value[1], Number(value[0])]
	})
)

export type ReversedGenreKey = keyof typeof reversedGenre

export class BicycleGenreModel {
	public readonly value: number
	constructor(value: number) {
		this.value = value
	}
	public toJSON(): number {
		return this.value
	}
	public toNumber(): number {
		return this.value
	}
	public toString(): string {
		return this.value.toString()
	}
	public getDisplayName(): string {
		return BICYCLE_GENRE_LIST[this.value as GenreKey]
	}
	public static getDisplayName(key: number): string {
		if (!(key in BICYCLE_GENRE_LIST))
			throw new Error(`got invalid key ${key}`)
		return BICYCLE_GENRE_LIST[key as GenreKey]
	}
	public static getNumber(displayName: BICYCLE_GENRE_LIST_VALUES): number {
		return Number(reversedGenre[displayName])
	}
	public static list(): Array<[string, string]> {
		return Object.entries(BICYCLE_GENRE_LIST)
	}
}

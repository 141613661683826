import { customAlphabet } from 'nanoid'

const alphabet = '0123456789abcdefghijklmnopqrstuvwxyz'
const nanoid = customAlphabet(alphabet, 4)

export abstract class BaseDisplayIDModel {
  public readonly prefix: string
  public readonly value: string

  constructor(prefix: string, value?: string) {
    this.prefix = prefix
    if (!value) {
      this.value = this.generateDisplayID()
      return
    }
    if (!this.validateDisplayID(value))
      throw new Error(`invalid displayID: ${value}`)
    this.value = value
  }
  protected validateDisplayID(value: string): boolean {
    if (!this.prefix)
      throw new Error(`prefix is undefined: ${this.prefix}`)
    return value.startsWith(this.prefix)
  }
  protected generateDisplayID() {
    if (!this.prefix)
      throw new Error(`prefix is undefined: ${this.prefix}`)
    return `${this.prefix}-${nanoid()}-${nanoid()}-${nanoid()}-${nanoid()}`
  }
  public toJSON() {
    return this.value
  }
  public toString() {
    return this.value
  }
}

export class AccountDisplayIDModel extends BaseDisplayIDModel {
  // @ts-ignore
  private readonly _brand: never
  constructor(value?: string) { super('7fc9', value) }
}

export class AccountPlanDisplayIDModel extends BaseDisplayIDModel {
  // @ts-ignore
  private readonly _brand: never
  constructor(value?: string) { super('2261', value) }
}

export class AccountPlanCouponDisplayIDModel extends BaseDisplayIDModel {
  // @ts-ignore
  private readonly _brand: never
  constructor(value?: string) { super('906a', value) }
}

export class AccountSubscriptionDisplayIDModel extends BaseDisplayIDModel {
  // @ts-ignore
  private readonly _brand: never
  constructor(value?: string) { super('4bcb', value) }
}

export class BicycleDisplayIDModel extends BaseDisplayIDModel {
  // @ts-ignore
  private readonly _brand: never
  constructor(value?: string) { super('6f6e', value) }
}

export class BicycleRequestDisplayIDModel extends BaseDisplayIDModel {
  // @ts-ignore
  private readonly _brand: never
  constructor(value?: string) { super('c9eb', value) }
}

export class FileDisplayIDModel extends BaseDisplayIDModel {
  // @ts-ignore
  private readonly _brand: never
  constructor(value?: string) { super('a561', value) }
}

export class InvoiceDisplayIDModel extends BaseDisplayIDModel {
  // @ts-ignore
  private readonly _brand: never
  constructor(value?: string) { super('e6f2', value) }
}

export class InvoiceRequestDisplayIDModel extends BaseDisplayIDModel {
  // @ts-ignore
  private readonly _brand: never
  constructor(value?: string) { super('e65f', value) }
}

export class MakerDisplayIDModel extends BaseDisplayIDModel {
  // @ts-ignore
  private readonly _brand: never
  constructor(value?: string) { super('c580', value) }
}

export class OwnerDisplayIDModel extends BaseDisplayIDModel {
  // @ts-ignore
  private readonly _brand: never
  constructor(value?: string) { super('2dc0', value) }
}

export class PartDisplayIDModel extends BaseDisplayIDModel {
  // @ts-ignore
  private readonly _brand: never
  constructor(value?: string) { super('aff2', value) }
}

export class ProductDisplayIDModel extends BaseDisplayIDModel {
  // @ts-ignore
  private readonly _brand: never
  constructor(value?: string) { super('9b8f', value) }
}

export class ShopDisplayIDModel extends BaseDisplayIDModel {
  // @ts-ignore
  private readonly _brand: never
  constructor(value?: string) { super('e23e', value) }
}

export class StaffDisplayIDModel extends BaseDisplayIDModel {
  // @ts-ignore
  private readonly _brand: never
  constructor(value?: string) { super('df19', value) }
}

export class SupplyDisplayIDModel extends BaseDisplayIDModel {
  // @ts-ignore
  private readonly _brand: never
  constructor(value?: string) { super('fd71', value) }
}

export class TaxRateDisplayIDModel extends BaseDisplayIDModel {
  // @ts-ignore
  private readonly _brand: never
  constructor(value?: string) { super('a48a', value) }
}

export class UserDisplayIDModel extends BaseDisplayIDModel {
  // @ts-ignore
  private readonly _brand: never
  constructor(value?: string) { super('fa2a', value) }
}

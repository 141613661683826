export const productType = {
  0: '自転車',
  1: 'パーツ',
  2: '用品',
}

export type ProductTypeKey = keyof typeof productType

export const reversedProductType = Object.fromEntries(
  Object.entries(productType).map(function(value) {
    return [value[1], Number(value[0])]
  })
)

export type ReversedProductTypeKey = keyof typeof productType

export class ProductTypeModel {
  private value: number
  constructor(value: number) {
    this.value = value
  }
  public toJSON() {
    return this.value
  }
  public toNumber(): number {
    return this.value
  }
  public toString() {
    return this.value.toString()
  }
  public getDisplayName(): string {
    return productType[this.value as ProductTypeKey]
  }
  public static getDisplayName(key: number): string {
    if (!(key in productType))
      throw new Error(`got invalid key ${key}`)
    return productType[key as ProductTypeKey]
  }
  public static getNumber(displayName: string): number {
    return Number(reversedProductType[displayName])
  }
}

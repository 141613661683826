import { customAlphabet } from 'nanoid'
const alphabet = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
const nanoid = customAlphabet(alphabet, 16)

export abstract class StripeDisplayIDModel {
  public readonly prefix: string
  public readonly value: string
  constructor(prefix: string, value?: string) {
    this.prefix = prefix
    if (!value) {
      this.value = this.generateDisplayID()
      return
    }
    if (!this.validateDisplayID(value))
      throw new Error(`invalid displayID: ${value}`)
    this.value = value
  }
  protected validateDisplayID(value: string): boolean {
    if (!this.prefix)
      throw new Error(`prefix is undefined: ${this.prefix}`)
    return value.startsWith(this.prefix)
  }
  protected generateDisplayID() {
    if (!this.prefix)
      throw new Error(`prefix is undefined: ${this.prefix}`)
    return `${this.prefix}_${nanoid()}`
  }
  public toJSON() {
    return this.value
  }
  public toString() {
    return this.value
  }
}

export class StripeAccountDisplayIDModel extends StripeDisplayIDModel {
  // @ts-ignore
  private readonly _brand: never
  constructor(value?: string) { super('acct', value) }
}

export class StripeSubscriptionDisplayIDModel extends StripeDisplayIDModel {
  // @ts-ignore
  private readonly _brand: never
  constructor(value?: string) { super('sub', value) }
}

export class StripeCustomerDisplayIDModel extends StripeDisplayIDModel {
  // @ts-ignore
  private readonly _brand: never
  constructor(value?: string) { super('cus', value) }
}

export class StripeDiscountDisplayIDModel extends StripeDisplayIDModel {
  // @ts-ignore
  private readonly _brand: never
  constructor(value?: string) { super('di', value) }
}

export class StripePriceDisplayIDModel extends StripeDisplayIDModel {
  // @ts-ignore
  private readonly _brand: never
  constructor(value?: string) { super('price', value) }
}

export class StripeProductDisplayIDModel extends StripeDisplayIDModel {
  // @ts-ignore
  private readonly _brand: never
  constructor(value?: string) { super('prod', value) }
}

export class StripeCouponDisplayIDModel extends StripeDisplayIDModel {
  // @ts-ignore
  private readonly _brand: never
  constructor(value?: string) { super('coupon', value) }
}

export class StripePaymentIntentDisplayIDModel extends StripeDisplayIDModel {
  // @ts-ignore
  private readonly _brand: never
  constructor(value?: string) { super('pi', value) }
}

export class StripeCheckoutSessionDisplayIDModel extends StripeDisplayIDModel {
  // @ts-ignore
  private readonly _brand: never
  constructor(value?: string) { super('cs', value) }
}

export class StripeTaxRateDisplayIDModel extends StripeDisplayIDModel {
  // @ts-ignore
  private readonly _brand: never
  constructor(value?: string) { super('txr', value) }
}
